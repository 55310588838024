<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <!-- <a class="logo" (click)="navigateHome()"
      ><img class="logo-img" src="/assets/images/authlogo.png" alt="logo"
    /></a> -->
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <!-- <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <nb-action class="user-action">
      <nb-user
        [nbContextMenu]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="user?.email || 'Sfera'"
        [picture]="user?.photo"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
